<template>
    <div >
      <b-overlay :show="loading" :style="{minHieght:'50px'}">
        <template #overlay>
          <div class="text-primary d-flex align-items-center">
            <b-spinner label="Loading..." variant="primary"></b-spinner>
            <h4 class="ml-1 text-primary">{{loadedStatus}}% Loaded</h4>
          </div>
        </template>
      <div id="webviewer">
        <div class="d-flex justify-content-center align-items-center"  >
          <div class="pdf-div" :style="{width:`${zoom}%`}" >
            <vue-pdf-embed :style="{width:'100%'}"  ref="pdfDoc" :page="currentPage" :annotationLayer="true"  imageResourcesPath="@/assets/images/app-icons/doc.png" @rendered="handleDocumentRender" @loading-failed="renderFailed" @progress="fileUploadStatus" :source="initialDoc"/>
          </div>
  
        </div>
      </div>
      <div class="position-absolute d-flex align-items-center controls-card justify-content-center">
        <feather-icon icon="ChevronUpIcon" :class="[currentPage<2?'disabled-btn':'']" class="mx-8 cursor-pointer" size="24" @click="()=>currentPage-=1"></feather-icon>
        <div >{{ currentPage }} of {{totalPages}}</div>
        <feather-icon icon="ChevronDownIcon" :class="[currentPage>=totalPages?'disabled-btn':'']" class="mx-8 cursor-pointer" size="24" @click="()=>currentPage+=1"></feather-icon>
        <div class="border-left  ">
          <feather-icon icon="ZoomInIcon" class="mx-8 cursor-pointer" :class="[zoom>=100?'disabled-btn':'']" size="24" @click="zoomIn()" ></feather-icon>
          <feather-icon icon="ZoomOutIcon" class="mx-8 cursor-pointer" :class="[zoom<=70?'disabled-btn':'']" size="24" @click="zoomOut"></feather-icon>
        </div>
      </div>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  import { BOverlay,BSpinner } from 'bootstrap-vue'
  export default {
    props: { 
      initialDoc: { type: String },
    },
    components: {
      VuePdfEmbed,
      BOverlay,
      BSpinner,
    },
    data() {
      return {
       show:false,
       currentPage: 1,
       totalPages: 1,
       loadedStatus:0,
       loading: true,
       scale: 1.5,
       zoom:85,
      
      }
    },
    methods:{
      handleDocumentRender(){
        this.totalPages = this.$refs.pdfDoc.pageCount
        this.loading = false
        this.$emit('currentPage',this.currentPage)
        this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
      },
      moveNext(){
        this.currentPage++;
      },
      fileUploadStatus(e){
        this.loadedStatus = (e.loaded/e.total*100).toFixed(0)
      },
      movePrev(){
        this.currentPage--;
      },
      zoomIn(){
       this.zoom+=5
        // this.$refs.pdfViewer.style
        // this.scale+=0.25
      },
      zoomOut(){
       this.zoom-=5
      },
      renderFailed(){
      this.$emit('renderFailed');
      this.loading=false;
      setTimeout(()=>this.$router.push('/invoices'),[7000])
      
    }
    }
  }
  </script>
  <style scoped>
  @media(min-width: 573px){
    #webviewer {
      height: 80vh;
      overflow-y: scroll;
      position: relative;
    }
  }
  .controls-card{
    background-color: #ffffff;
    width: 250px;
    padding: 10px 15px;
    border-radius: 30px;
    border:1px solid #D0D5DD;
    bottom:3%;
    left: 25%;
  }
  @media(max-width:425px){
    .controls-card{
      background-color: #ffffff;
      width: 250px;
      padding: 10px 15px;
      border-radius: 30px;
      border:1px solid #D0D5DD;
      bottom:3%;
      left: 15%;
    }
  }
  .mx-8{
    margin-left: 8px;
    margin-right: 8px;
  }
  .px-8{
    padding-left: 8px;
    padding-right: 8px;
  }
  .disabled-btn{
    pointer-events: none;
    cursor:default;
    opacity: .5;
  }
  .vue-pdf-embed div {
    margin: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  }
  .pdf-viewer{
    width: 90%;
    height: 95%;
  }
  #webviewer::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  #webviewer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  #webviewer::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  #webviewer::-webkit-scrollbar-thumb:hover {
    background: #004bff; 
  }
  </style>